import { action, observable } from "mobx";
import { ApiService, ApiUrl, Constants } from "helpers";
import Alert from 'react-s-alert';

const emailFooterTemplateStore = observable(
  {
    isFormDirty: false,
    emailFooterTemplate: undefined,
    emailUnsubscribeTemplate: undefined,

    resetFooterTemplate() {
      this.emailFooterTemplate = undefined
      this.emailUnsubscribeTemplate = undefined;
      this.isFormDirty = false;
    },

    setIsFormDirty(isFormDirty) {
      this.isFormDirty = isFormDirty;
    },

    setEmailFooterTemplate(emailFooterTemplate) {
      this.emailFooterTemplate = emailFooterTemplate;
      this.setIsFormDirty(true);
    },

    setEmailUnsubscribeTemplate(emailUnsubscribeTemplate) {
      this.emailUnsubscribeTemplate = emailUnsubscribeTemplate;
      this.setIsFormDirty(true);
    },

    getEmailFooterSetting() {
      return (ApiService.get(ApiUrl.getEmailFooterSetting, "", Constants.ALERT_FETCH_FAILED).then(
        action(
          response => {
            this.setEmailFooterTemplate(JSON.parse(response.data.result.htmlFooterTemplate));
            this.setEmailUnsubscribeTemplate(JSON.parse(response.data.result.htmlUnsubscribeTemplate));
            this.setIsFormDirty(false);
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },

    saveEmailFooterSetting() {
      const data = {
        htmlFooterTemplate: JSON.stringify(this.emailFooterTemplate),
        htmlUnsubscribeTemplate: JSON.stringify(this.emailUnsubscribeTemplate),
      };

      return (ApiService.edit(ApiUrl.updateEmailFooterSetting, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setIsFormDirty(false)
              Alert.success("Changes is saved successfully");
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          error => {
            return Promise.reject(error);
          }
        )
      ));
    },
  }
);

export default emailFooterTemplateStore;
