import { ApiService, ApiUrl, Enum, Constants, FormatDate } from "helpers";
import { action, observable } from "mobx";
import Alert from 'react-s-alert';
import moment from "moment";
import { FormatDateUtc } from "helpers/FormatDateTable";
import { EmailReportGridColumnName } from "../../views/Reports/Email/EmailReportsGrid/EmailReportGridConstant";
import { EmailReportsFieldName, HeaderTabName } from "helpers/Constants";

const emailReportStore = observable(
    {
        emailReports: [],
        filteredEmailReports: [],
        emailReportsFilterModel: {},
        filterSearchText: "",
        emailReportChartConfigDataset: [],
        emailReportChartConfigDatasetClient: [],
        dataId: undefined,
        dataIdClient: undefined,
        campaignName: undefined,
        clientCampaignName: undefined,
        selectedReport: undefined,
        selectedReportClient: undefined,
        selectedDetailsType: { typeId: 0, label: null },
        selectedEmailReportDetail: {},
        selectedEmailReportLinkDetail: {},
        selectedDetailsToken: null,
        emailReportDetails: [],
        emailReportDetailPercentage: [],
        emailReportDetailsLastSendDate: moment.utc().format("DD MMMM YYYY"),
        emailReportDetailsUnsubscribed: [],
        emailReportDetailsDelivered: [],
        emailReportDetailsBounced: [],
        emailReportDetailsInvalid: [],
        emailReportDetailsOpened: [],
        emailReportDetailsLinksClicked: [],
        emailReportDetailsLinks: [],
        emailReportDetailsCandidateLinks: [],
        emailReportDetailsSent: [],
        chartInstance: null,
        emailReportLinkTitle: "",
        emailReportLinkOriginalUrl: "",
        comingFromType: 6,
        defaultTab: HeaderTabName.TAB_CANDIDATE,
        filterGrid: [],
        sortGrid: [],
        agGridRowsPage: 0,
        agGridRowsPageClient: 0,
        agGridTotalRow: 0,
        agGridTotalRowClient: 0,
        agGridTotalPage: 0,
        agGridTotalPageClient: 0,

        gridColumn: [
            {
                id: 1,
                colId: EmailReportsFieldName.CAMPAIGN_NAME,
                headerName: EmailReportGridColumnName.CampaignName,
                field: EmailReportsFieldName.CAMPAIGN_NAME,
                minWidth: 220,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 2,
                colId: EmailReportsFieldName.AUTOMATION_NAME,
                headerName: EmailReportGridColumnName.AutomationName,
                field: EmailReportsFieldName.AUTOMATION_NAME,
                minWidth: 220,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 3,
                colId: EmailReportsFieldName.LAST_SEND_DATE,
                headerName: EmailReportGridColumnName.LastSendDate,
                field: EmailReportsFieldName.LAST_SEND_DATE,
                minWidth: 120,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 4,
                colId: EmailReportsFieldName.TOTAL_SENT,
                headerName: EmailReportGridColumnName.TotalSent,
                field: EmailReportsFieldName.TOTAL_SENT,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {

                id: 5,
                colId: EmailReportsFieldName.OPENED_PERCENTAGE,
                headerName: EmailReportGridColumnName.Opened,
                field: EmailReportsFieldName.OPENED_PERCENTAGE,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 6,
                colId: EmailReportsFieldName.LINKS_CLICKED_PERCENTAGE,
                headerName: EmailReportGridColumnName.Clicked,
                field: EmailReportsFieldName.LINKS_CLICKED_PERCENTAGE,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 7,
                colId: EmailReportsFieldName.BOUNCED_PERCENTAGE,
                headerName: EmailReportGridColumnName.Bounced,
                field: EmailReportsFieldName.BOUNCED_PERCENTAGE,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            },
            {
                id: 8,
                colId: EmailReportsFieldName.UNSUBSCRIBED_PERCENTAGE,
                headerName: EmailReportGridColumnName.Unsubscribed,
                field: EmailReportsFieldName.UNSUBSCRIBED_PERCENTAGE,
                minWidth: 50,
                hide: false,
                hideColConfig: false,
                checkedColConfig: true,
            }
        ],
        getEmailReports() {
            return (ApiService.get(ApiUrl.getAllEmailReports).then(
                action(
                    response => {
                        this.setEmailReports(response.data.result);
                        return Promise.resolve(response);
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ));
        },
        setFilterGrid(filterGrid) {
            this.filterGrid = filterGrid;
        },

        setSortGrid(sortGrid) {
            this.sortGrid = sortGrid;
        },

        setAgGridRowsPage(agGridRowsPage) {
            this.agGridRowsPage = agGridRowsPage;
        },

        setAgGridRowsPageClient(agGridRowsPageClient) {
            this.agGridRowsPageClient = agGridRowsPageClient;
        },

        setAgGridTotalRow(agGridTotalRow) {
            this.agGridTotalRow = agGridTotalRow;
        },

        setAgGridTotalRowClient(agGridTotalRow) {
            this.agGridTotalRowClient = agGridTotalRow;
        },

        setAgGridTotalPage(agGridTotalPage) {
            this.agGridTotalPage = agGridTotalPage;
        },

        setAgGridTotalPageClient(agGridTotalPageClient) {
            this.agGridTotalPageClient = agGridTotalPageClient;
        },

        updateGridColumn(gridColumnData) {
            this.gridColumn = gridColumnData;
        },

        setDataId(id) {
            this.dataId = id
        },

        setDataIdClient(id) {
            this.dataIdClient = id
        },

        setComingFromType(type) {
            this.comingFromType = type
        },

        setDefaultTab(tab) {
            this.defaultTab = tab
        },

        setSelectedReport(report) {
            this.selectedReport = report
        },

        setSelectedReportClient(report) {
            this.selectedReport = report
        },

        setEmailReportDetailsLastSendDate(report) {
            this.emailReportDetailsLastSendDate = report
        },

        setSelectedDetailsType(detailsType) {
            this.selectedDetailsType = detailsType
        },

        setEmailReportLinkOriginalUrl(emailReportLinkOriginalUrl) {
            this.emailReportLinkOriginalUrl = emailReportLinkOriginalUrl
        },

        setEmailReportLinkTitle(emailReportLinkTitle) {
            this.emailReportLinkTitle = emailReportLinkTitle
        },

        setEmailReportDetailPercentage(emailReportDetailPercentage) {
            this.emailReportDetailPercentage = [];
            this.emailReportDetailPercentage = [...emailReportDetailPercentage];
        },

        setSelectedEmailReportDetail(emailReportDetail) {
            this.selectedEmailReportDetail = {};
            this.selectedEmailReportDetail = emailReportDetail
        },

        setSelectedEmailReportLinkDetail(emailReportLinkDetail) {
            // this.selectedEmailReportLinkDetail = {};
            this.selectedEmailReportLinkDetail = emailReportLinkDetail
        },

        setSelectedDetailsToken(selectedDetailsToken) {
            this.selectedDetailsToken = selectedDetailsToken
        },

        setCampaignName(name) {
            this.campaignName = name
        },

        setClientCampaignName(name) {
            this.clientCampaignName = name
        },

        setEmailReports(reports) {
            this.emailReports = reports
            this.filteredEmailReports = [...reports]
            this.emailReportsFilterModel = {};
        },

        setfilterEmailReportsModel(model) {
            this.emailReportsFilterModel = model;
        },

        setEmailReportDetails(reportDetails) {
            this.emailReportDetails = [];
            this.emailReportDetails = [...reportDetails];
        },

        setEmailReportDetailsUnsubscribed(emailReportDetailsUnsubscribed) {
            this.emailReportDetailsUnsubscribed = [];
            this.emailReportDetailsUnsubscribed = [...emailReportDetailsUnsubscribed];
        },

        setEmailReportDetailsDelivered(emailReportDetailsDelivered) {
            this.emailReportDetailsDelivered = [];
            this.emailReportDetailsDelivered = [...emailReportDetailsDelivered];
        },

        setEmailReportDetailsBounced(emailReportDetailsBounced) {
            this.emailReportDetailsBounced = [];
            this.emailReportDetailsBounced = [...emailReportDetailsBounced];
        },

        setEmailReportDetailsInvalid(emailReportDetailsInvalid) {
            this.emailReportDetailsInvalid = [];
            this.emailReportDetailsInvalid = [...emailReportDetailsInvalid];
        },

        setEmailReportDetailsOpened(emailReportDetailsOpened) {
            this.emailReportDetailsOpened = [];
            this.emailReportDetailsOpened = [...emailReportDetailsOpened];
        },

        setEmailReportDetailsLinksClicked(emailReportDetailsLinksClicked) {
            this.emailReportDetailsLinksClicked = [];
            this.emailReportDetailsLinksClicked = [...emailReportDetailsLinksClicked];
        },

        setEmailReportDetailsLinks(emailReportDetailsLinks) {
            this.emailReportDetailsLinks = [];
            this.emailReportDetailsLinks = [...emailReportDetailsLinks];
        },

        setEmailReportDetailsCandidateLinks(emailReportDetailsCandidateLinks) {
            this.emailReportDetailsCandidateLinks = [];
            this.emailReportDetailsCandidateLinks = [...emailReportDetailsCandidateLinks];
        },

        setEmailReportChartConfigDataset(dataset) {
            this.emailReportChartConfigDataset = [];
            this.emailReportChartConfigDataset = [...dataset];
        },

        setEmailReportChartConfigDatasetClient(dataset) {
            this.emailReportChartConfigDatasetClient = [];
            this.emailReportChartConfigDatasetClient = [...dataset];
        },

        setEmailReportDetailsSent(emailReportDetailsSent) {
            this.emailReportDetailsSent = [];
            this.emailReportDetailsSent = [...emailReportDetailsSent];
        },

        setChartInstance(chartInstance) {
            this.chartInstance = chartInstance;
        },

        getDataId() {
            return this.dataId
        },

        getDataIdClient() {
            return this.dataIdClient
        },

        countDataSet(data) {
            return [data?.openedPercentage.toFixed(1), data?.linkClickedPercentage.toFixed(1), data?.bouncedPercentage.toFixed(1), data?.unsubscribePercentage.toFixed(1)]
        },

        resetReportType() {
            this.selectedDetailsType = { typeId: 0, label: null };
            this.selectedDetailsToken = null;
        },

        resetEmailReportStore() {
            this.emailReportsCandidate = undefined;
            this.emailReportsClient = undefined;
            this.emailReportChartConfigDataset = [];
            this.emailReportChartConfigDatasetClient = [];
            this.dataId = undefined;
            this.dataIdClient = undefined;
            this.campaignName = undefined;
            this.clientCampaignName = undefined;
            this.selectedReport = undefined;
            this.selectedDetailsType = { typeId: 0, label: null };
            this.selectedEmailReportDetail = {};
            this.selectedEmailReportLinkDetail = {};
            this.selectedDetailsToken = null;
            this.emailReportDetails = [];
            this.emailReportDetailPercentage = [];
            this.emailReportDetailsLastSendDate = moment.utc().format("DD MMMM YYYY");
            this.emailReportDetailsUnsubscribed = [];
            this.emailReportDetailsDelivered = [];
            this.emailReportDetailsBounced = [];
            this.emailReportDetailsInvalid = [];
            this.emailReportDetailsOpened = [];
            this.emailReportDetailsLinksClicked = [];
            this.emailReportDetailsLinks = [];
            this.emailReportDetailsCandidateLinks = [];
            this.emailReportDetailsSent = [];
            this.chartInstance = null;
            this.comingFromType = 6;
            this.defaultTab = HeaderTabName.TAB_CANDIDATE;
        },

        getEmailReportServerSide(request) {
            const body = {
                param: JSON.stringify(request)
            };
            return (ApiService.post(`${ApiUrl.getEmailReports}`, body).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setAgGridTotalRow(response.data.result.totalCount);
                            return Promise.resolve(response.data);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);

                            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ))
        },

        getEmailClientReportServerSide(request) {
            const body = {
                param: JSON.stringify(request)
            };

            return (ApiService.post(`${ApiUrl.getEmailClientReports}`, body).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            this.setAgGridTotalRowClient(response.data.result.totalCount);
                            return Promise.resolve(response.data);
                        } else {
                            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);

                            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
                        }
                    }
                )
            ).catch(
                action(
                    err => {
                        return Promise.reject(err);
                    }
                )
            ))
        },

        getEmailReportDetails(contactType, token) {
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportSelectedDetails : ApiUrl.getEmailReportClientSelectedDetails;

            return (ApiService.getDetail(apiUrlEmailReport, token).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            if (response.data.result[0]) {
                                this.setSelectedEmailReportDetail(response.data.result[0]);
                                this.setEmailReportDetailPercentage([
                                    response.data.result[0].openedPercentage.toFixed(2),
                                    response.data.result[0].linkClickedPercentage.toFixed(2),
                                    response.data.result[0].bouncedPercentage.toFixed(2),
                                    response.data.result[0].unsubscribePercentage.toFixed(2),
                                    //need set percentage value for invalid
                                ]);
                                if (contactType === Enum.ContactType.Candidate) {
                                    this.setEmailReportDetailsLastSendDate(FormatDateUtc(response.data.result[0]?.reports.lastSendDate));
                                } else {
                                    this.setEmailReportDetailsLastSendDate(FormatDateUtc(response.data.result[0]?.clientReportEmail.lastSendDate));
                                }
                            } else {
                                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
                            }
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsUnsubscribed(contactType, token) {
            const params = `${Enum.EmailReportType.Unsubscribed.typeId}?reportToken=${token}`;
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;

            return (ApiService.getDetail(apiUrlEmailReport, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsUnsubscribed(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsDelivered(contactType, token) {
            const params = `${Enum.EmailReportType.Delivered.typeId}?reportToken=${token}`;
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;

            return (ApiService.getDetail(apiUrlEmailReport, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsDelivered(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsBounced(contactType, token) {
            const params = `${Enum.EmailReportType.Bounced.typeId}?reportToken=${token}`;
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;

            return (ApiService.getDetail(apiUrlEmailReport, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsBounced(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsOpened(contactType, token) {
            const params = `${Enum.EmailReportType.Opened.typeId}?reportToken=${token}`;
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;

            return (ApiService.getDetail(apiUrlEmailReport, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsOpened(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsLinksClicked(contactType, token) {
            const data = {
                reportToken: token,
            };
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportClicked : ApiUrl.getEmailReportClientClicked;

            return (ApiService.post(apiUrlEmailReport, data).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsLinksClicked(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsLinks(contactType, token) {
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportLink : ApiUrl.getEmailReportClientLink;

            return (ApiService.getDetail(apiUrlEmailReport, token).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsLinks(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportLinkPageDetails(contactType, token, linkName, linkUrl) {
            const data = {
                reportToken: token,
                linkName: linkName,
                linkUrl: linkUrl,
            };
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportLinkDetails : ApiUrl.getEmailReportClientLinkDetails;

            return (ApiService.post(apiUrlEmailReport, data).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            Alert.success("Email Report Link has been loaded");
                            this.setEmailReportDetailsCandidateLinks(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsSent(contactType, token) {
            const params = `${Enum.EmailReportType.Processsed.typeId}?reportToken=${token}`;
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;

            return (ApiService.getDetail(apiUrlEmailReport, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            this.setEmailReportDetailsSent(response.data.result);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        getEmailReportDetailsInvalid(contactType, token) {
            const params = `${Enum.EmailReportType.Invalid.typeId}?reportToken=${token}`;
            const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;

            return (ApiService.getDetail(apiUrlEmailReport, params).then(
                action(
                    response => {
                        if (response && response.status === 200 && response.data && !response.data.isError) {
                            // TODO HERE if something necessary, for now just return data exactly from API
                            //when total invalid is 0, it does not display data from total invalid #7046 
                            this.setEmailReportDetailsInvalid(this.selectedEmailReportDetail?.totalInvalid > 0 ? response.data.result : []);
                        } else {
                            Alert.error("Oops, Something went wrong! Please try again.");
                        }
                        return Promise.resolve(response.data);
                    }
                )
            ).catch(
                action(
                    err => {
                        Alert.error(err);
                        return Promise.reject(err);
                    }
                )
            ));
        },

        setEmailReportSearchFilterString(searchString) {
            const { FormatDateUtc } = FormatDate;
            //TODO: possible extract to helpers for searching strings, or extract to store helpers
            function ApplySearchStringIfCreatedDateExists(item, searchString) {
                return !!item.lastSendDate && FormatDateUtc(item?.lastSendDate).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
            }

            this.filterSearchText = searchString;

            this.filteredEmailReports = this.emailReports.filter(item =>
                ApplySearchStringIfCreatedDateExists(item, searchString) ||
                item.emailName?.toLowerCase().includes(searchString.toLowerCase()) ||
                item.campaignName?.toLowerCase().includes(searchString.toLowerCase()) ||
                item.category?.toLowerCase().includes(searchString.toLowerCase()) ||
                item.dateCreated?.toLowerCase().includes(searchString.toLowerCase())
            );
        },
    }
);

export default emailReportStore;
